@import '@/assets/scss/mixins';

.container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    height: 100vh;
    .title {
        @include style-font(60px, 60px, -2.2px);
        @include theme($font-stack, 1000, $settings, var(--core5));
    }
    .text {
        @include style-font(24px, 30px, -0.4px);
        @include theme($font-stack, 1000, $settings, var(--core5));
    }
}

@include screen('medium') {
    .container {
        gap: 24px;
        padding-left: 20px;
        padding-right: 20px;

        .title {
            @include style-font(48px, 48px, -1.4px);
            font-weight: 1000;
        }
        .text {
            @include style-font(20px, 26px, -0.2px);
            font-weight: 1000;
        }
    }
}

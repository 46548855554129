@import '@/assets/scss/mixins';
.root {
    display: flex;
    justify-content: center;
    padding-top: 192px;
    .wrapper {
        .title {
            margin-bottom: 32px;
        }
        .minds {
            display: flex;
            flex-wrap: wrap;
            .mind {
                $margin: 48px;
                min-height: 128px;
                min-width: calc(50% - $margin);
                max-width: calc(50% - $margin);
                margin-right: $margin;
            }
            .divider {
                background-color: var(--core80);
                height: 1px;
                width: 100%;
                margin: 24px auto;
            }
        }
    }
}

@include screen('large') {
    .root {
        .wrapper {
            width: max-content;
            min-width: min-content;
            max-width: 353px;
        }
    }
    .mind {
        min-width: 100% !important;
        min-height: max-content;
    }
}
@include screen('medium') {
    .root {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.uniText_container {
    position: relative;
    max-width: 100%;
}
.slider {
    margin-top: 32px;
    overflow-x: hidden;
    :global(.swiper) {
        overflow: initial;
    }
    @media (max-width: 700px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}
.hidden {
    visibility: hidden;
}

@import '@/assets/scss/mixins';

.dark {
    background-color: var(--core5) !important;
    color: var(--core95) !important;
    .card {
        background-color: var(--core95) !important;
        color: var(--core5) !important;
        .preTitle {
            color: var(--core40) !important;
        }
    }
}
.root {
    padding: 128px 0;
    .container {
        display: flex;
        gap: 48px;
        width: fit-content;
        margin: 0 auto;
        flex-wrap: wrap;
        .card {
            margin: 0 auto 0;
            width: 396px;
            max-height: 586px;
            background-color: var(--core10);
            color: var(--core95);
            cursor: pointer;
            .info {
                padding: 32px 48px 48px 24px;
                .title {
                    padding-top: 8px;
                    //typography H2
                    @include style-font(36px, 44px, -1px);
                }
                .preTitle {
                    color: var(--core60);
                    overflow-y: scroll;
                }
            }
        }
    }
}

@include screen('large') {
    .root {
        .container {
            gap: 0;
            display: flex;
            min-width: 100%;
            max-width: 100%;
        }
    }
}

@include screen('medium') {
    .root {
        padding: 0 0;
        .container {
            gap: 0;
            display: flex;
            .card {
                width: 100%;
                .info {
                    padding: 32px 48px 48px 24px;
                    .preTitle {
                        color: var(--core60);
                        margin-bottom: 8px;
                        overflow-y: scroll;
                    }
                    .title {
                        //typography H2
                        @include style-font(30px, 36px, -0.8px);
                    }
                }

                &:last-child {
                    text-align: right;
                    .info {
                        padding: 32px 24px 48px 48px;
                        .preTitle {
                            color: var(--core60);
                            margin-bottom: 8px;
                            overflow-y: scroll;
                        }
                    }
                }
            }
        }
    }
}

@include screen('small') {
    .card {
        width: 100% !important;
    }
}

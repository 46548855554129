@import '../../assets/scss/mixins';
.root {
    padding-top: 48px;
    .line {
        margin: auto;
        height: 4px;
        width: 96px;
        background-color: var(--core20);
    }
    .wrapper {
        margin: 32px auto !important;
        .quote {
            color: var(--core20);
        }
        .person {
            margin: 24px auto 0 auto;
            width: 70%;
            display: flex;
            align-items: center;
            .nameWrapper {
                margin-left: 16px;
                text-align: center;
                .name {
                    color: var(--core40);
                }
            }
        }
    }
}

.dark {
    .line {
        background-color: var(--core80);
    }
    .wrapper {
        .quote {
            color: var(--core80);
        }
        .person {
            .nameWrapper {
                .name {
                    color: var(--core60);
                }
            }
        }
    }
}

@include screen('small') {
    .wrapper {
        max-width: 393px !important;

        .quote {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .name {
        text-align: left !important;
    }
    .person {
        width: 85% !important;
    }
}

@import 'assets/scss/_mixins.scss';

.container {
    padding-top: 72px;
    background-color: $dark-text-color;

    .topPadding {
        padding-top: 200px;
    }
    .animateimage {
        animation: fade-in $duration-image ease;
    }
    .title {
        //typography Article Light
        @include style-font(36px, 42px, -1px);
        font-weight: 700;
        font-feature-settings: $settings-numerals;
        color: $light-text-color;
        padding-bottom: 24px;
    }
    .intro,
    .image {
        padding-top: 24px;
    }
    .image {
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .divider {
        border-top: 1px solid var(--core80);
    }
    .author {
        display: flex;
        padding-top: 24px;
    }
    .name {
        text-decoration: underline;
    }
    .subtitle {
        padding-bottom: 4px;
    }
    .hr {
        margin: 24px 0px;
        border-top: 1px solid var(--core80);
    }
    .date {
        .dot {
            padding: 0px 4px;
        }
    }
}

@include screen('medium') {
    .container {
        padding-top: 160px;

        .topPadding {
            padding-top: 0px;
        }
        .title,
        .author,
        .subtitleAuthor,
        .intro,
        .subtitle,
        .date {
            padding-left: 20px;
            padding-right: 20px;
        }
        .divider,
        .hr {
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}
@media (max-width: 860px) {
    .container {
        padding-top: 160px;

        .topPadding {
            padding-top: 0px;
        }
        .title,
        .author,
        .subtitleAuthor,
        .intro,
        .subtitle,
        .date {
            padding-left: 20px;
            padding-right: 20px;
        }
        .divider,
        .hr {
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0.9;
    }
}
@media (prefers-reduced-motion) {
    .animateimage {
        animation: none;
    }
}

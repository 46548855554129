@import '../../../../assets/scss/mixins';

.isDark {
    .row_container {
        border-top: 1px solid var(--core20);
        background-color: var(--core5);
        &:not(.open) {
            &:hover {
                background-color: var(--core10);
            }
            &:active {
                background-color: var(--core15);
            }
        }

        //typography H3
        .h3 {
            @include style-font(24px, 34px, -0.4px);
            font-feature-settings: $settings-numerals;
        }
        @media (max-width: 700px) {
            .h3 {
                @include style-font(24px, 34px, -0.6px);
                font-feature-settings: $settings-numerals;
            }
        }
    }

    .innerText {
        background-color: transparent;
    }
    .row {
        i {
            @include theme($dark-theme...);
        }
    }
    .number {
        @include theme($dark-theme...);
    }
}

.container {
    position: relative;
    padding-top: 64px;
    .animateIconOpen {
        animation: icon-open 0.6s ease;
    }
    .animateIconClose {
        animation: icon-close 0.6s ease;
    }
}
.row_container {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 20px;
    border-top: 1px solid var(--core80);
    background-color: var(--core95);
    cursor: pointer;
    &:not(.open) {
        &:hover {
            background-color: var(--core90);
        }
    }

    //typography H3
    .h3 {
        @include style-font(24px, 34px, -0.6px);
        font-feature-settings: $settings-numerals;
    }
    @media (max-width: 700px) {
        .h3 {
            @include style-font(24px, 34px, -0.6px);
            font-feature-settings: $settings-numerals;
            font-weight: 2200;
        }
    }
}

.border {
    border-bottom: 1px solid transparent;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    h3 {
        position: relative;
        transition: 0.1s linear;
    }
    i {
        @include theme($light-theme...);
        transition: 0.3s ease-out;
    }
}

.desc {
    overflow-y: hidden;
    min-width: 100%;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // transition: height 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    display: grid;
    grid-template-rows: 0fr;
    transition: 350ms ease-in-out;

    &.active {
        grid-template-rows: 1fr;
    }

    .contentWrapper {
        overflow: hidden;
    }
}
.innerText {
    position: relative;
    padding-bottom: 64px;
    width: 100%;
    overflow: hidden;
}
.hidden {
    display: none;
}
.number {
    @include theme($light-theme...);
    @include style-font(24px, 34px, -0.6px); //typography h3
    padding-right: 15px;
    @media (max-width: 700px) {
        padding-right: 10px;
    }
}

@keyframes icon-open {
    from {
        transform: rotate(90deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@keyframes icon-close {
    from {
        transform: rotate(-90deg);
    }
    to {
        transform: rotate(-180deg);
    }
}

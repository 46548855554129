@import '../../assets/scss/mixins';
.root {
    color: var(--core5);
    background-color: var(--standardBackgroundLight);
    padding-top: 96px;
}

.content {
    display: flex;
    margin-top: 24px;
    gap: 24px;
    min-height: 400px;

    .splitter {
        min-height: 100%;
        width: 1px;
        background-color: var(--core80);
    }
}

@include screen('medium') {
    .wrapper {
        .title {
            padding: 0 20px;
        }
    }

    .content {
        flex-direction: column;

        .infoStyle {
            color: var(--core40) !important;
        }
        .splitter {
            height: 1px;
            width: 80%;
            margin: 0 auto;
        }
    }
}

@include screen('small') {
    .wrapper {
        padding: 0 0 !important;
    }
}
@media (max-width: 860px) {
    .content {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@import '../../assets/scss/mixins';

.container,
.container_dark,
.accordion_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 32px;
    overflow: hidden;
    img {
        position: relative;
        margin: 32px auto;
        min-width: $largeLayout;
        max-width: 100%;
        transform: translateX((-$largeLayout + $standardLayout) / 2);
        image-rendering: -webkit-optimize-contrast;
    }
    p {
        margin: 12px 0;
    }
    h2 {
        padding-top: 12px;
    }
    p {
        margin: 12px 0;
    }
    h2 {
        padding-top: 12px;
    }
}
.accordion_container {
    padding-top: 32px;
    @media (max-width: 700px) {
        padding: 32px 0px 0px 0px;
    }
}

.title {
    padding-top: 32px;
}
.standalone {
    max-width: $standardLayout;
    padding-top: 64px;
    //typography H3
    @include style-font(24px, 34px, -0.6px);
    font-feature-settings: $settings-numerals;
}
.content {
    justify-content: center;
    align-items: center;
}
.buzzword_container {
    @media (max-width: 700px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (max-width: 1298px) {
        padding-top: 32px;
    }
}
.light {
    h1,
    h2,
    h3,
    h4,
    p,
    a,
    small,
    blockquote {
        @include theme($light-theme-uni-text...);
    }
    h1 {
        @include style-font(60px, 64px, -2.2px);
    }
    h2 {
        @include style-font(36px, 44px, -1px);
    }
    h3 {
        @include style-font(24px, 34px, -0.6px);
        font-feature-settings: $settings-numerals;
    }
    h4 {
        font-weight: 700;
        @include style-font(20px, 30px, -0.4px);
    }
    p {
        @include style-font(20px, 30px, -0.4px);
    }
    a {
        @include style-font(20px, 24px, -0.4px);
        text-decoration-line: underline;
    }
    small {
        @include style-font(16px, 22px, -0.2px);
    }
    blockquote {
        @include style-font(30px, 40px, -1.6px);
    }
    ol {
        list-style-type: decimal;
        list-style-position: outside;
        padding-left: 30px;
        p {
            padding-bottom: 0;
        }
    }
    ol > li {
        padding-left: 12px;
    }
    ol > li > p {
        margin: 0px;
    }
    ol > li > ol {
        list-style-type: lower-alpha;
    }
    ul {
        list-style-type: none;
        padding-left: 12px;
        p {
            padding-left: 4px;
            padding-bottom: 0;
        }
    }
    ul > li {
        position: relative;
        padding-left: 12px;
    }
    ul > li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 4px;
        height: 4px;
        background-color: var(--core20);
        border-radius: 50%;
        transform: translateY(-50%);
    }
    ul > li > ul > li {
        position: relative;
        padding-left: 12px;
    }

    ul > li > ul > li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 4px;
        height: 4px;
        border: 0.5px solid var(--core20);
        background-color: transparent;
        transform: translateY(-50%);
    }
    ol {
        list-style-type: decimal;
        list-style-position: outside;
        padding-left: 30px;
        p {
            padding-bottom: 0;
        }
    }
    ol > li {
        padding-left: 12px;
    }
    ol > li > p {
        margin: 0px;
    }
    ol > li > ol {
        list-style-type: lower-alpha;
    }
    ul {
        list-style-type: none;
        padding-left: 12px;
        p {
            padding-left: 4px;
            padding-bottom: 0;
        }
    }
    ul > li {
        position: relative;
        padding-left: 12px;
    }
    ul > li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 4px;
        height: 4px;
        background-color: var(--core20);
        border-radius: 50%;
        transform: translateY(-50%);
    }
    ul > li > ul > li {
        position: relative;
        padding-left: 12px;
    }

    ul > li > ul > li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 4px;
        height: 4px;
        border: 0.5px solid var(--core20);
        background-color: transparent;
        transform: translateY(-50%);
    }
    max-width: 100%;
}
.dark {
    h1,
    h2,
    h3,
    h4,
    p,
    a,
    small,
    blockquote {
        @include theme($dark-theme-uni-text...);
    }
    h1 {
        @include style-font(60px, 64px, -2px);
    }
    h2 {
        @include style-font(36px, 44px, -0.8px);
    }
    h3 {
        @include style-font(24px, 34px, -0.4px);
        font-feature-settings: $settings-numerals;
    }
    h4 {
        font-weight: 600;
        @include style-font(20px, 30px, -0.2px);
        font-feature-settings: $settings;
        color: $dark-text-color;
    }
    p {
        @include style-font(20px, 30px, -0.2px);
    }
    a {
        @include style-font(20px, 24px, -0.2px);
        font-feature-settings: $settings-numerals;
        text-decoration-line: underline;
    }
    small {
        font-size: 16px;
        line-height: 22px;
        font-feature-settings: $settings-numerals;
    }
    blockquote {
        @include style-font(30px, 40px, -1.4px);
    }
    ol {
        list-style-type: decimal;
        list-style-position: outside;
        padding-left: 30px;
        p {
            padding-bottom: 0;
        }
    }
    ol > li {
        padding-left: 12px;
    }
    ol > li > p {
        margin: 0px;
    }
    ol > li > ol {
        list-style-type: lower-alpha;
    }
    ul {
        list-style-type: none;
        padding-left: 12px;
        p {
            padding-left: 4px;
            padding-bottom: 0;
        }
    }
    ul > li {
        position: relative;
        padding-left: 12px;
    }
    ul > li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 12px;
        width: 4px;
        height: 4px;
        background-color: var(--core80);
        border-radius: 50%;
        transform: translateY(-50%);
    }
    ul > li > ul > li {
        position: relative;
        padding-left: 12px;
    }

    ul > li > ul > li::before {
        content: '';
        position: absolute;
        left: 0;
        top: 12px;
        width: 4px;
        height: 4px;
        border: 0.5px solid var(--core80);
        background-color: transparent;
        transform: translateY(-50%);
    }
    max-width: 100%;
}

@media (max-width: 700px) {
    .light {
        h1 {
            @include style-font(48px, 54px, -1.4px);
        }
        h2 {
            @include style-font(30px, 36px, -0.8px);
        }
        h3 {
            @include style-font(24px, 34px, -0.6px);
            font-feature-settings: $settings-numerals;
        }
        h4 {
            font-weight: 700;
            @include style-font(20px, 30px, -0.4px);
        }
        p {
            @include style-font(20px, 28px, -0.6px);
        }
        a {
            @include style-font(20px, 24px, -0.6px);
            text-decoration-line: underline;
            font-feature-settings: $settings-numerals;
        }
        small {
            font-size: 16px;
            line-height: 22px;
            font-feature-settings: $settings-numerals;
        }
        blockquote {
            @include style-font(24px, 32px, -1px);
        }
    }
    .dark {
        h1 {
            @include style-font(48px, 54px, -1.2px);
        }
        h2 {
            @include style-font(30px, 36px, -0.6px);
        }
        h3 {
            @include style-font(24px, 34px, -0.6px);
            font-feature-settings: $settings-numerals;
        }
        h4 {
            font-weight: 600;
            @include style-font(20px, 30px, -0.2px);
        }
        p {
            @include style-font(20px, 30px, -0.4px);
        }
        a {
            @include style-font(20px, 24px, -0.4px);
            text-decoration-line: underline;
            font-feature-settings: $settings-numerals;
        }
        small {
            font-size: 16px;
            line-height: 22px;
            font-feature-settings: $settings-numerals;
        }
        blockquote {
            @include style-font(24px, 32px, -0.8px);
        }
    }
}

@include screen('medium') {
    .container,
    .container_dark,
    .accordion_container {
        padding-left: 20px;
        padding-right: 20px;
        img {
            position: relative;
            min-width: calc(100% + 40px);
            transform: translateX(-20px);
        }
    }
    .standalone {
        max-width: 100%;
        padding-top: 64px;
    }
}

.rte {
    @include screen('medium') {
        img {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

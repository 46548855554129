@import '@/assets/scss/mixins';

.container {
    padding-top: 48px;
    user-select: none;
    @media (max-width: 920px) {
        min-width: 100%;
        max-width: 100%;
    }
}

.video {
    position: relative;
    cursor: pointer;
    height: 512px;

    width: 100%;
    video {
        object-fit: cover;
    }

    .controls {
        transition: background-color 0.7s ease, opacity 0.7s ease, visibility 0.7s ease;
        background-color: rgba(0, 0, 0, 0.4);
        border: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
    }
    .overlay:not(.isPlaying):hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.hide {
    opacity: 0;
    visibility: hidden;
}

.overlay {
    opacity: 1;
    visibility: visible;

    .icon {
        color: var(--core95);
        font-size: 70px;
    }
}

.description {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
}

.sourcecolor {
    color: var(--core40);
}
.desccolor {
    color: var(--core20);
    p {
        @include style-font(16px, 22px, -0.2px);
    }
    &.isDark {
        p {
            @include theme($dark-theme...);
        }
    }
}

@include screen('medium') {
    .video {
        height: 400px;
        transform: translateX(-20px);
        min-width: calc(100% + 20px) !important;
        width: calc(100% + 20px) !important;
        max-width: calc(100% + 20px) !important;
    }
    .description {
        padding-left: 20px;
        padding-left: 20px;
    }
}
@include screen('small') {
    .video {
        height: 256px;
    }
}
@include screen('extra-small') {
    .video {
        height: 180px;
    }
}

.dark {
    .sourcecolor {
        color: var(--core60) !important;
    }
    .desccolor {
        color: var(--core80) !important;
    }
}

@import 'assets/scss/_mixins.scss';
.root {
    color: var(--core80);
    background-color: var(--core5);
    padding: 192px 0 128px 0;
    font-size: 20px;
    font-weight: $font-weight-book; //p compressed
    line-height: 24px;
    letter-spacing: -0.2px;
    position: relative;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column:not(:last-child) {
    margin-right: 64px;
}

.column {
    min-width: max-content;
    a {
        display: inline-block;
        text-decoration: underline;
    }
    br {
        content: '';
        display: block;
        margin: 4px 0 !important;
    }
}

.footerLink {
    display: inline-block;
    margin-right: -8px !important;
    &:last-child {
        margin-right: -24px !important;
    }
}
@include screen('large') {
    .root {
        padding: 192px 20px 128px 20px;
    }
}
@media (max-width: 990px) {
    .root {
        .tablet {
            width: 100%;
            align-items: center;
        }
    }
    .row {
        width: 100%;
        max-width: 600px;
    }
}

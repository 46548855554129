@import '../../assets/scss/mixins';

.root {
    padding-top: 160px;
    .wrapper {
        min-height: 192px;
    }
}
.logo {
    width: 192px;
    height: 192px;
}
@include screen('medium') {
    .root {
        padding: 80px 20px 0;
    }
}

@import '@/assets/scss/mixins';

.projectBox {
    cursor: pointer;
    width: 50%;
    // margin-right: 24px;
    .title {
        text-align: left;
        margin-bottom: 32px;
        margin-top: 24px;
    }
    .infoStyle {
        margin-top: 24px;
        margin-bottom: 4px;
        font-size: 16px;
        font-style: normal;
        font-weight: $font-weight;
        line-height: 22px;
        letter-spacing: -0.2px;
        color: var(--core20);
        span {
            margin-right: 4px;
        }
    }
    .link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .projectImage {
        width: 100%;
    }
}

@include screen('medium') {
    .projectBox {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
        .title {
            padding: 0 20px;
        }
        .projectImage {
            width: 100%;
        }
        .infoStyle {
            padding: 0 20px;
            span {
                margin-right: 4px;
            }
        }
        .title {
            margin-bottom: 0;
        }
    }
}

@import '../../assets/scss/mixins';
$cardHeiht: 512px;
.dark {
    .back {
        min-height: 100%;
        background-color: var(--core10);
        color: var(--core80);
        &:hover {
            background-color: var(--core15);
            color: var(--core95);
        }
    }
}

.back {
    transform: rotateY(-180deg);
    border-radius: 12px;
    overflow: hidden;
    min-height: 100%;
    background-color: var(--core90);
    color: var(--core20);
    .descriptionWrapper {
        height: calc(100% - 64px);
        padding: 24px;
        font-size: 20px;
        font-weight: $font-weight-book; //p
        line-height: 30px;
        letter-spacing: -0.2px;
        text-align: left;
    }
    .iconWrapper {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        height: 64px;
        .icon {
            margin-right: 24px;
        }
    }
    &:hover {
        background-color: var(--core85);
        color: var(--core5);
    }
}
.root {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.info {
    color: var(--core95);
    z-index: 1;
    text-align: left;
    width: calc(100% - 48px);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    bottom: 24px;
    left: 24px;

    .title {
        color: inherit;
    }
    .preTitle {
        color: inherit;
    }
    .iconContainer {
        height: 54px;
        width: 54px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.card {
    height: $cardHeiht;
    width: 384px;
    left: 0px;
    top: 0px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
}

// ANIMATION
.card.flipped .back {
    transform: rotateY(0deg);
}

.card.flipped .front {
    transform: rotateY(180deg);
}

.card:nth-child(even) .back {
    transform: rotateY(0deg);
}

.card:nth-child(even).flipped .back {
    transform: rotateY(-180deg);
}

.front {
    .card.flipped & {
        transform: rotateY(180deg);
    }
    .card:nth-child(even).flipped & {
        transform: rotateY(-180deg);
    }
}
.front,
.back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: ease-in-out 600ms;
    .iconWrapper {
        width: 100%;
        .minus {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            height: 54px;
            width: 54px;
            padding-bottom: 30px;
        }
    }
}
// END ANIMATION

.imageWrapper {
    position: relative;
    &::after {
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
        height: 192px;
        width: 100%;
        position: absolute;
        top: 320px;
        left: 0;
        border-radius: 12px;
    }
}
.image {
    width: 100%;
    height: $cardHeiht;
    object-fit: cover;
    border-radius: 12px;
}
.icon {
    cursor: pointer;
}

@include screen('medium') {
    .card {
        height: $cardHeiht;
        width: 100%;
    }
}

@import '@/assets/scss/mixins';

.root {
    margin-top: 10px;
    color: white;
    
}

.wrap {
    position: relative;
    overflow: hidden;

    .layer {
        z-index: 2;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(90deg, #0d0c0b 0%, rgba(13, 12, 11, 0) 10%, rgba(13, 12, 11, 0) 90%, #0d0c0b 100%);
    }
}

.animWrap {
    display: flex;
    gap: 24px;
    align-items: center;
}

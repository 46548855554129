@import '@/assets/scss/mixins';
.container {
    position: relative;
    padding-top: 48px;
}
.dark {
    .row_container {
        border-top: 1px solid var(--core30);
        background-color: var(--core10);
        &:not(.open) {
            &:hover {
                background-color: var(--core15);
            }
            &:active {
                background-color: var(--core20);
            }
        }
    }
    // .open {
    //     background-color: var(--core15) !important;
    // }
    .bottom {
        border-bottom: 1px solid var(--core30);
    }
    .number {
        color: var(--core80);
    }
    .title {
        color: var(--core80);
        i {
            color: var(--core60);
        }
    }
    .openText {
        i {
            color: var(--core80);
        }
    }
}
.row_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px 20px;
    cursor: pointer;

    border-top: 1px solid var(--core70);

    &:not(.open) {
        &:hover {
            background-color: var(--core90);
        }
        &:active {
            background-color: var(--core85);
        }
    }
}
// .open {
//     background-color: var(--core85);
// }

.bottom {
    border-bottom: 1px solid var(--core70);
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $standardLayout;
    @media (max-width: 640px) {
        width: 100%;
    }
    h3 {
        position: relative;
        transition: 0.1s linear;
    }
    i {
        position: relative;
        transition: 0.3s ease-out;
    }
}

.desc {
    overflow-y: hidden;
    width: $standardLayout;
    @media (max-width: 640px) {
        width: 100%;
    }
    transition: height 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
.hidden {
    display: none;
}
.number {
    color: var(--core20);
    padding-right: 15px;
    @media (max-width: 700px) {
        padding-right: 10px;
    }
}
.title {
    user-select: none;
    letter-spacing: -0.2px;
    color: var(--core20);
    i {
        color: var(--core40);
    }
}

.openText {
    color: var(--core5);
    i {
        color: var(--core5);
    }
}
.animateIconOpen {
    animation: icon-open 0.6s ease;
}
.animateIconClose {
    animation: icon-close 0.6s ease;
}
@keyframes icon-open {
    from {
        transform: rotate(-90deg);
    }
    to {
        transform: rotate(-180deg);
    }
}
@keyframes icon-close {
    from {
        transform: rotate(90deg);
    }
    to {
        transform: rotate(180deg);
    }
}

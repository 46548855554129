@import '../../../../assets/scss/mixins';

.slider_outer_container {
    position: relative;
    padding-top: 32px;
}
.swiper_slide {
    padding-bottom: 25px;
}
.hidden {
    display: none;
}

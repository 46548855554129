@import '../../assets/scss/mixins';
.name {
    color: var(--core20);
}
.role {
    color: var(--core40);
}
.dark {
    .name {
        color: var(--core80);
    }
    .role {
        color: var(--core60);
    }
}
.root {
    display: flex;
    justify-content: center;
}
.wrapper {
    min-width: $standardLayout;
    max-width: $standardLayout;
    display: flex;
    justify-content: flex-end;
    align-self: center;
}
.infos {
    min-height: 100%;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: end;
}

@include screen('medium') {
    .wrapper {
        min-width: 100%;
        padding: 0px 20px;
    }
}

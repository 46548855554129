@import '@/assets/scss/mixins';
$rte-padding-top: 1em;
$list-padding-left: 20px;

.rte {
    p {
        @include style-font(20px, 30px, -0.2px);
    }

    & > p + * {
        padding-top: $rte-padding-top;
    }

    & > .heading + * {
        padding-top: $rte-padding-top;
    }

    & > .heading {
        .italic {
            font-style: normal !important;
        }
    }

    & > .heading {
        padding-bottom: 1em;
        b {
            font-weight: 850 !important;
        }
    }

    & > * + p {
        padding-top: $rte-padding-top;
    }

    // list items

    & > .olRte + h2,
    & > .olRte + h1,
    & > .olRte + h3 {
        padding-top: 1em;
    }
    & > * + .olRte,
    & > * + .ulRte {
        padding-top: 1em;
    }

    & > .ulRte + .olRte,
    & > .olRte + .ulRte {
        padding-top: 32px;
    }

    li + li {
        // padding-top: 1em;
    }
    li {
        p {
            span {
                display: block;
            }
        }
    }
    .italic {
        font-style: italic;
    }
    .bold {
        font-weight: 600;
    }

    .olRte,
    .ulRte {
        padding-left: $list-padding-left;
        list-style-position: outside;
    }

    .olRte {
        list-style-type: decimal;
    }

    // image & span
    p > span {
        display: inline;
    }
    p > img {
        display: inline-block;
    }

    p > * + .imageWrapper {
        margin-top: $rte-padding-top;
    }

    p > .imageWrapper + span {
        margin-top: $rte-padding-top;
    }

    .imageWrapper {
        display: block;
        width: 100%;
    }

    .imageRte image {
        object-fit: contain;
    }
    span {
        color: inherit !important;
    }
}

@import '@/assets/scss/mixins.scss';

.dark.card {
    background-color: var(--core5);
    border: 1px solid var(--core20);
    &:hover {
        background-color: var(--core10);
    }

    .button {
        color: var(--core80);
    }
    .info {
        .title {
            color: var(--core80);
            margin-bottom: 4px;
        }
        .description {
            color: var(--core60);
        }
    }
    .icon {
        color: var(--core80);
    }
    .closeBtn {
        color: var(--core20);
    }
}

.card {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    $cardSize: 280px;
    background-color: var(--core95);
    border: 1px solid var(--core80);
    width: $cardSize;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 288px;
    min-height: 424px;
    align-items: center;

    cursor: pointer;
    .topCard {
        width: 100%;
        img {
            max-height: 100%;
        }
    }
    &:hover {
        background-color: var(--core90);
    }
    .info {
        display: flex;
        flex-direction: column;
        padding: 12px;
        width: 100%;

        .title {
            color: var(--core15);
            text-align: left;
        }
        .description {
            color: var(--core40);
        }
    }
    .icon {
        width: 100%;
        padding: 12px;
        display: flex;
        justify-content: flex-end;
    }
}

.card.active {
    grid-column: 1/-1;
    width: 100%;
    max-width: 100%;
    background-color: var(--core95);
    cursor: unset;

    &:nth-child(1) {
        grid-row: 2/5;
    }

    &.dark {
        background-color: var(--core5);
    }
}

@include screen('large') {
    // $cardSize: calc((100% / 3) - 24px);
    $cardSize: 100%;
    .card {
        min-width: $cardSize;
    }
}

@include screen('medium') {
    $cardSize: 100%;
    .card {
        min-width: $cardSize;
    }
}
@include screen('small') {
    .card {
        min-width: 100%;
        &.active:nth-child(1) {
            grid-row: 1/1;
        }
    }
}

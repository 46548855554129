@import '@/assets/scss/mixins';

.blogBox {
    cursor: pointer;
    background-color: var(--core10);
    // margin-left: 24px;
    padding: 24px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .titleBlog {
        color: var(--core95);
        text-align: left;
        margin-bottom: 32px;
        margin-top: 24px;
    }
    .infoStyle {
        color: var(--core60);
    }
    .articleBy {
        color: var(--core95);
        display: flex;
        align-items: flex-end;
        gap: 16px;
    }
    .info {
        color: var(--core60);
    }
    .link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.authorInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
}

@include screen('medium') {
    .blogBox {
        background-color: var(--core90);
        width: 100%;
        margin-top: 24px;
        margin-left: 0;
        .titleBlog {
            margin-bottom: 48px;
            color: var(--core20) !important;
        }
    }
}

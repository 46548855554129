@import '../../assets/scss/mixins';

.root {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    padding-top: 192px;
    width: 100%;
    max-width: $standardLayout;
    &.mainTitle {
        padding-top: 64px;
    }

    .microTitle {
        color: var(--core40);
        margin-bottom: 4px;
    }
    .microTitleDark {
        color: var(--core60);
        margin-bottom: 4px;
    }
    .leadTextDark {
        color: var(--core80);
    }
    .leadText {
        color: var(--core20);
    }
    .underline {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 7px;
    }
    .ctaSection {
        //typography h1
        @include style-font(60px, 64px, -2.2px);
        -moz-hyphens: auto;
        -o-hyphens: auto;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
    .ctaSectionDark {
        //typography h1
        @include style-font(60px, 64px, -2px);
        -moz-hyphens: auto;
        -o-hyphens: auto;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
}

@include screen('medium') {
    .root {
        padding: 0 20px;
    }
    .ctaSection {
        //typography h1
        @include style-font(48px, 54px, -1.4px);
    }
    .ctaSectionDark {
        //typography h1
        @include style-font(48px, 54px, -1.2px);
    }
}

@import '../../assets/scss/mixins';

.root {
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.wrapper {
    border-radius: 12px;
    overflow: initial;

    :global(.swiper) {
        overflow: initial;
    }
}
.slide {
    padding-bottom: 32px;
}
@include screen('medium') {
    .root {
        display: block;
        margin: 0 auto !important;
        max-width: 70%;
        .wrapper {
            max-width: 100%;
        }
    }
    .overlayNext {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    }
}

@include screen('small') {
    .root {
        padding: 0 20px;
        max-width: 100%;
    }
}

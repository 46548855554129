@import 'assets/scss/_mixins.scss';

.root {
    background-color: var(--core95);
    padding-top: 64px;
    padding-bottom: 64px;
    button {
        color: var(--core20);
        &:hover {
            color: var(--core30);
        }
    }
}

.wrap {
    max-width: 840px;
    width: 100%;
    h2,
    .text {
        color: #332e2e;
        font-weight: 400;
    }
}

.text {
    margin-top: 34px;
    margin-bottom: 26px;
}

.label {
    margin-left: 14.3%;
    font-weight: bold;
    color: var(--core40);
}

.image-wrap {
    width: 100%;
    margin-top: 30px;
}

.body {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 22px;
    word-break: break-word;
}

@include screen('medium') {
    .label {
        margin-left: 0;
        padding-left: 20px;
    }
    .body {
        padding: 0 20px;
    }
}

@import '@/assets/scss/mixins';

.dark {
    .subTitle {
        color: var(--core80) !important;
    }
}

.postSection,
.preSection {
    margin: auto;
    max-width: $standardLayout;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    color: var(--core20);
}

.preSection {
    padding-bottom: 24px;
    margin-bottom: 24px;
}
.postSection {
    border-top: 1px solid var(--core70);
    padding-top: 24px;
    margin-top: 24px;
}
.subTitle {
    color: var(--core40);
}
.underline {
    text-decoration: underline;
    text-underline-offset: 3px;
}
.divider {
    border-bottom: 1px solid var(--core70);
}

@include screen('medium') {
    .preSection,
    .postSection {
        padding-right: 20px;
        padding-left: 20px;
    }
}

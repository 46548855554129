@import '../../assets/scss/mixins';

.btn,
.btn_dark,
.cta,
.cta_dark,
.universal {
    height: 48px;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 25px;
    border-style: solid;
    padding: 12px 24px;
    transition: opacity 0.15s linear;
    position: relative;
    gap: 4px;
    font-size: 20px;
    line-height: 24px;
    direction: ltr;
    cursor: pointer;
    @include theme($font-stack, $font-weight, $settings-numerals, var(--core80));
    i {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        margin-left: 10px;
    }
}
.btn {
    color: var(--core20);
    letter-spacing: -0.4px;
    &:hover {
        color: var(--core40);
    }
    &:disabled {
        color: var(--core40);
        border: none;
    }
}
.btn_dark {
    font-weight: $font-weight-book; //p compressed
    letter-spacing: -0.2px;
    &:hover {
        color: var(--core60);
    }
    &:disabled {
        color: var(--core60);
    }
}
.cta {
    letter-spacing: -0.4px;
    color: var(--core20);
    i {
        transition: all 0.2s ease;
        transform: translateX(0);
    }
    &:hover {
        color: var(--core5);
        i {
            transform: translateX(8px);
        }
    }
    &:visited {
        color: var(--core40);
    }
}
.cta_dark {
    font-weight: $font-weight-book;
    letter-spacing: -0.2px;
    color: var(--core80);
    i {
        transition: all 0.2s ease;
        transform: translateX(0);
    }
    &:hover {
        color: var(--core95);
        i {
            transform: translateX(8px);
        }
    }
    &:visited {
        color: var(--core60);
    }
}
.universal {
    gap: 2px;
    color: var(--core20);
    letter-spacing: -0.4px;
    &:hover {
        background-color: var(--core5);
        color: var(--core90);
        border: none;
    }
    &:visited {
        background-color: var(--core20);
        color: var(--core90);
        border: none;
    }
}
@media (max-width: 700px) {
    .btn {
        letter-spacing: -0.6px;
    }
    .btn_dark {
        letter-spacing: -0.4px;
    }
    .cta {
        letter-spacing: -0.6px;
        color: var(--core20);
    }
    .cta_dark {
        letter-spacing: -0.4px;
    }
    .universal {
        letter-spacing: -0.6px;
    }
}

@import '@/assets/scss/mixins';

.authorBox {
    border: 1px solid var(--core80);
    padding: 48px;
    .title {
        text-align: left;
        margin-bottom: 32px;
        color: var(--core20);
    }
    .author {
        display: flex;
        gap: 48px;
        .infos {
            flex: 1;
            .top {
                // h4 typography
                text-align: left;
                margin-bottom: 32px;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: -0.4;
                color: var(--core20);
                span {
                    margin-right: 4px;
                }
                .name {
                    text-decoration: underline;
                }
            }
            .bottom {
                font-size: 20px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: -0.4px;
                text-decoration-line: underline;
                // p link typography
                .email {
                    font-weight: $font-weight;
                    color: var(--core20);
                    display: block;
                }
                .number,
                .linkedin {
                    margin-top: 8px;
                    font-weight: $font-weight;
                    color: var(--core20);
                }
            }
        }
    }
}
.infoStyle {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-weight;
    line-height: 22px;
    letter-spacing: -0.2px;
    span {
        margin-right: 4px;
    }
}

@include screen('medium') {
    .authorBox {
        padding: 48px 0;
        .title {
            padding: 0 20px;
            text-align: left;
            margin-bottom: 24px;
            margin-top: 24px;
        }
        .author {
            flex-direction: column;
            gap: 0;

            .authorImage {
                margin-right: 0;
                width: 100%;
            }
            .infos {
                padding: 0 20px;
                margin-top: 24px;
                margin-bottom: 48px;

                .dot {
                    display: none;
                }
                .role,
                .name {
                    display: block;
                }
                .role {
                    color: var(--core40);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: -0.2px;
                }
                .top {
                    margin-bottom: 24px !important;

                    span {
                        margin-right: 4px;
                    }
                    .name {
                        text-decoration: underline;
                    }
                }
                .btnGroup {
                    margin-left: -20px;
                    margin-right: -20px;
                }
            }
        }
    }
    .authorImage {
        padding-left: 20px;
        padding-right: 20px;
    }
}

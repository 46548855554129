@import '@/assets/scss/mixins';

.space {
    height: 22px;
}

.root {
    width: 100%;
    padding: 64px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .wrapper {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        transition: height 20s ease-out;
        max-width: 1224px;
        width: 100%;
        gap: 24px;
        @media screen and (max-width: 1300px) {
            max-width: 812px;
            width: 100%;
        }
        @media screen and (max-width: 850px) {
            justify-content: center;
        }

        .swiper {
            padding-right: 20px;
        }
    }
    .button {
        color: var(--core80);
        font-size: 20px;
        font-weight: $font-weight;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin: 32px auto 0;

        border-radius: 25px;
        border: 2px solid transparent;
        padding: 12px 24px;
        width: fit-content;
        cursor: pointer;
    }
}

.card {
    width: 392px;
    height: 520px;
    perspective: 1000px;

    &.light {
        .frontCard,
        .backCard {
            background-color: var(--core90);
        }

        .frontCard {
            .infos {
                .preTitleWrap {
                    .preTitle {
                        color: var(--core40);
                    }
                }
                .name {
                    color: var(--core5);
                }
            }
        }

        .backCard {
            .backDescription {
                color: var(--core5);
            }
            .actionButton {
                color: var(--core5);
            }
        }
    }

    .frontCard,
    .backCard {
        border-radius: 12px;
        background-color: var(--core10);
        width: 100%;
        height: 100%;
        position: absolute;
        backface-visibility: hidden;
        transition: transform 0.6s;
    }

    .frontCard {
        cursor: pointer;
        margin-bottom: 32px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        .infos {
            flex-grow: 1;
            .preTitleWrap {
                margin-bottom: 16px;
                margin-top: 32px;
                .preTitle {
                    color: var(--core60);
                }
            }
            .name {
                color: var(--core95);
                font-size: 60px;
                font-weight: 800;
                line-height: 60px;
                word-wrap: break-word;
            }
        }
        .bottomIconWrap {
            text-align: end;
            i {
                transform: rotate(180deg);
            }
        }
    }

    .backCard {
        padding-top: 24px;
        transform: rotateY(180deg);
        display: flex;
        flex-direction: column;
        .mindnentity {
            height: 344px;
            object-fit: cover;
            opacity: 0.24px;
        }
        .backDescription {
            word-wrap: break-word;
            padding: 32px 24px;
            flex-grow: 1;
            color: #c6c4cc;
            max-height: 255px;
            font-size: 20px;
            font-weight: 350;
            line-height: 30px;
            letter-spacing: -0.2px;
        }
        .actionButton {
            cursor: pointer;
            border-radius: 0px 0px 12px 12px;
            display: flex;
            justify-content: center;
            gap: 12px;
            padding: 24px;

            color: var(--core5);
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.4px;
        }
    }

    &.flipped {
        .frontCard {
            transform: rotateY(-180deg);
        }

        .backCard {
            transform: rotateY(0deg);
        }
    }

    &:nth-child(3n) {
        margin-right: 0;
    }
}

.mindnentityWrap {
    display: flex;
    justify-content: center;
    .mask {
        object-fit: cover;
        height: 168px;
        width: 344px;
    }
}

@import '@/assets/scss/mixins';

.text {
    margin-left: 16px;
    max-width: 250px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.4px;
    text-decoration-line: underline;
}
.roleStyle {
    display: block;
    color: var(--core40);
}
.role {
    margin-top: 4px;
}
.htmlText {
    margin-top: 8px;
}
.involvedAs {
    display: block;
    color: var(--core20);
}
.content {
    display: flex;
    min-width: 100px;
}

@include screen('medium') {
    .image {
        margin-right: 16px;
    }
    .content {
        padding-left: 20px;
        padding-right: 20px;
    }
    .text {
        max-width: 600px;
    }
}

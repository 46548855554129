@import '@/assets/scss/mixins';
.root,
.dark {
    display: flex;
    flex-direction: column;
    gap: 96px;
    justify-content: center;
    .text {
        display: flex;
        gap: 48px;
        justify-content: center;
        @media screen and (min-width: 525px) {
            padding: 0 20px;
        }
        hr {
            margin-bottom: 24px;
            border-top: 1px solid var(--core80);
        }
        .description {
            max-width: 600px;
        }
        .title {
            max-width: 400px;
        }
        p {
            @include style-font(20px, 30px, -0.4px);
        }
        h2 {
            @include style-font(36px, 44px, -1px);
        }
        h3 {
            @include style-font(24px, 34px, -0.6px);
            font-feature-settings: $settings-numerals;
        }
        h4 {
            font-weight: 700;
            @include style-font(20px, 30px, -0.4px);
        }
    }
    .images {
        display: flex;

        .imgGallery {
            display: flex;
            gap: 12px;
            position: relative;
            .img {
                height: 192px;
                width: 192px;
            }
        }
    }
}
.dark {
    background-color: var(--core5);
    .text {
        color: var(--core95);
        hr {
            border-top: 1px solid var(--core20);
        }
        p {
            @include style-font(20px, 30px, -0.4px);
        }
        h2 {
            @include style-font(36px, 44px, -0.8px);
        }
        h3 {
            @include style-font(24px, 34px, -0.4px);
            font-feature-settings: $settings-numerals;
        }
        h4 {
            @include style-font(20px, 30px, -0.2px);
            font-feature-settings: $settings;
            color: $dark-text-color;
            font-weight: 500;
        }
    }
}
@media screen and (max-width: 580px) {
    .root,
    .dark {
        flex-direction: column;
        padding: 128px 0;

        .text {
            display: flex;
            flex-direction: column;
            gap: 48px;
            justify-content: center;

            .title,
            .description {
                max-width: 100%;
                padding: 0 20px;
            }
            h2 {
                @include style-font(30px, 36px, -0.8px);
            }
            h3 {
                @include style-font(24px, 34px, -0.6px);
                font-feature-settings: $settings-numerals;
                font-weight: 2200;
            }
            h4 {
                @include style-font(20px, 30px, -0.4px);
                font-weight: 700;
            }
            p {
                @include style-font(20px, 30px, -0.6px);
            }
        }

        .images {
            display: flex;
            flex-direction: column;
            gap: 48px;
            flex-wrap: wrap;
            overflow: hidden;

            .imgGallery {
                display: flex;
                gap: 12px;

                .img {
                    height: 128px;
                    width: 128px;
                }
            }
        }
    }

    .dark {
        .text {
            h2 {
                @include style-font(30px, 36px, -0.6px);
            }
            h3 {
                @include style-font(24px, 34px, -0.6px);
                font-feature-settings: $settings-numerals;
            }
            h4 {
                @include style-font(20px, 30px, -0.2px);
                font-weight: 500;
            }
            p {
                @include style-font(20px, 30px, -0.4px);
            }
        }
    }
}

@import '@/assets/scss/mixins';

.root {
    padding-top: 200px;
    background-color: $dark-text-color;
    .crosslink {
        padding-top: 128px;
    }
    .preFooter {
        background-color: $dark-text-color;
    }
    .divider {
        max-width: $largeLayout;
        margin: 0 auto;
        color: var(--core20);
    }
}

@include screen('medium') {
    .root {
        padding-top: 0;
        .divider {
            padding: 0 20px;
        }
    }
}
@media (max-width: 860px) {
    .root {
        padding-top: 0;
        .divider {
            padding: 0 20px;
        }
    }
}
.cardContainer {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: center;

    .card {
        width: 600px;
        display: flex;
        justify-content: flex-start;

        @include screen('medium') {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

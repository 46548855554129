@import '../../assets/scss/mixins';
.textAlignRight {
    .quote {
        text-align: start;
    }
    .images {
        order: 2;
    }
    .nameWrapper {
        order: 1;
        flex-direction: row-reverse;
    }
    .image {
        margin-right: 0;
    }
    .mindentity {
        margin-right: 0;
        margin-left: 24px;
    }

    .name,
    .remainingName {
        margin-right: 0px;
        margin-left: 4px;
        text-align: right;
    }
    .line {
        transform: scaleX(-1);
    }
    .subContent {
        padding-left: 24px;
    }
    .subInfo {
        text-align: right;
    }
    .quoteWrapper {
        text-align: left;
    }
}
.dark.root {
    background-color: var(--core5);
    .name,
    .remainingName {
        color: var(--core95);
    }
    .role {
        color: var(--core60);
    }
}
.quoteWrapper {
    text-align: right;
}
.wrapper {
    max-width: $largeLayout;
    min-width: $largeLayout;
    @media (max-width: $largeLayout) {
        min-width: fit-content;
        max-width: 100%;
    }
}
.root {
    background-color: var(--core95);
    display: flex;
    justify-content: center;
    padding: 64px 20px;
}
.quote {
    min-width: 100%;
}

.content {
    display: flex;
    margin-top: 32px;
}
.subContent {
    padding-right: 24px;
    flex-grow: 1;
}
.images {
    display: flex;
    align-items: center;
}
.mindentity {
    margin-right: 24px;
}

.role {
    color: var(--core40);
}
.role:last-of-type {
    margin-left: 4px;
}

.nameWrapper {
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
}

.name,
.remainingName {
    //typography quote
    margin-right: 4px;
    font-size: 30px;
    font-weight: $font-weight-light;
    line-height: 40px;
    letter-spacing: -1.4px;
    color: var(--core5);
    overflow-wrap: break-all;
    word-wrap: break-all;
    hyphens: auto;
    min-width: max-content;
}

.name.longName {
    min-width: min-content;
}
.line {
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom-right-radius: 2px;
    height: 23px;
    min-width: 20%;
    width: 55%;
    flex-grow: 1;
    border-left: 0px;
    border-top: 0px;
}

@include screen('medium') {
    .name,
    .remainingName,
    .role {
        //typography quote
        font-size: 24px;
        font-weight: $font-weight-light;
        line-height: 32px;
        letter-spacing: -0.8px;
    }

    .content {
        flex-direction: column-reverse;
    }

    .images {
        margin-top: 24px;
        order: 0;
    }
    .subContent {
        order: 3;
    }
    .textAlignRight {
        .images {
            justify-content: flex-end;
        }
    }
}

.wrapper {
    position: relative;
    .crop {
        clip-path: inset(0 24px calc(392px - 168px) 24px);
    }
}

.mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    min-height: 100%;

    opacity: 0;
    animation: blendIn 200ms ease-in forwards;
}

@keyframes blendIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@import 'assets/scss/_mixins.scss';
$minHeight: 1243px;
$padding: 128px;
$imageHeight: calc($minHeight - $padding);

.root {
    position: relative;
    background-color: var(--core5);
    padding-bottom: $padding;
}

.title,
.description {
    padding-top: 24px;
    letter-spacing: 0.2px;
    border-top: 1px solid var(--core95);
    * {
        color: var(--core95) !important;
    }
    span {
        line-height: 30px;
    }
}

.title {
    flex-grow: 2;
    margin-right: 48px;
}
.image1,
.image2 {
    width: 100%;
    min-height: $imageHeight;
    object-fit: cover;
}

.image1 {
    -webkit-clip-path: ellipse(100% 100% at 0% 0%);
    clip-path: ellipse(100% 100% at 0% 0%);
}
.image2 {
    -webkit-clip-path: polygon(
        25.38% 51.95%,
        0% 36.97%,
        0% 0%,
        39.61% 0%,
        61.93% 15.7%,
        84.25% 0%,
        99.86% 0%,
        99.86% 99.91%,
        61.93% 73.53%,
        26.96% 96.91%,
        0% 92.91%,
        0% 66.94%
    );
    clip-path: polygon(
        25.38% 51.95%,
        0% 36.97%,
        0% 0%,
        39.61% 0%,
        61.93% 15.7%,
        84.25% 0%,
        99.86% 0%,
        99.86% 99.91%,
        61.93% 73.53%,
        26.96% 96.91%,
        0% 92.91%,
        0% 66.94%
    );
}
.content {
    width: calc(100% - 40px);
    position: absolute;
    bottom: 0;
    left: 20px;
    display: flex;
    justify-content: center;
    padding-bottom: $padding;

    .description {
        max-width: 600px;
        span {
            letter-spacing: 0.2px;
        }
    }
    .title {
        max-width: 400px;
        min-width: 400px;
    }
}
.imageWrapper {
    position: relative;
    display: flex;
}

.imageContainer {
    width: calc(50% - 10px);
    &:first-child {
        margin-right: 32px;
    }
    &::after {
        content: '';
        position: absolute;
        background: linear-gradient(180deg, rgba(13, 12, 11, 0) 0%, #0d0c0b 100%);
        width: 100%;
        height: $minHeight / 2;
        bottom: 0px;
        left: 0;
    }
}

@include screen('large') {
    $minHeight: 600px;
    .layout {
        display: flex;
        justify-content: center;
    }
    .content {
        max-width: 500px;
        width: 100%;
        top: -90px;
        left: 0;
        position: relative;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        .title {
            min-width: 100%;
            margin-right: 0;
        }
        .description {
            margin-top: 48px;
            span {
                letter-spacing: 0.4px;
            }
        }
    }
    .imageContainer {
        &::after {
            height: $minHeight;
            bottom: 0;
        }
    }

    .image1,
    .image2 {
        min-height: $minHeight;
    }
}
@include screen('medium') {
    $minHeight: 400px;
    .imageContainer {
        &::after {
            height: $minHeight;
            bottom: 0;
        }
    }
    .content {
        .description {
            span {
                font-weight: 220 !important;
            }
        }
    }
    .image1,
    .image2 {
        min-height: $minHeight;
    }
}

@include screen('small') {
    $minHeight: 263px;
    .imageContainer {
        &::after {
            height: $minHeight;
            bottom: 0;
        }
    }
    .image1,
    .image2 {
        min-height: $minHeight;
    }
}

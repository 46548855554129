@import 'assets/scss/_mixins.scss';

.root {
    background-color: var(--core10);
    padding-top: 128px;
    padding-bottom: 128px;
}

.inner_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    .color {
        color: var(--core80);
        //typography H2
        @include style-font(36px, 44px, -0.8px);
    }
}

@include screen('medium') {
    .container {
        padding: 0 20px;

        .color {
            //typography H2
            @include style-font(30px, 36px, -0.6px);
        }
    }
}

@import '@/assets/scss/mixins';

.dark {
    .buttons {
        box-shadow: inset 0 0 0 1px var(--core20);
        color: var(--core60);

        .active {
            color: var(--core80);
        }
    }
    .button {
        &:hover {
            color: var(--core80);
        }
    }
}
.buttons {
    display: flex;
    max-width: max-content;
    z-index: 1;
    border-radius: 28px;
    height: 48px;
    overflow-x: scroll;
    overflow-y: hidden;
    box-shadow: inset 0 0 0 1px var(--core80);
    color: var(--core40);
    user-select: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    &::-webkit-scrollbar {
        display: none;
    }
    .active {
        color: var(--core20);
    }
}
.selector {
    z-index: -1;
    width: 0;
    height: 47px;
    border: 2px solid transparent;
    border-radius: 28px;
    position: absolute;
    transition: all 0.5s ease;
    top: 0;
    left: 0;
}

.button {
    min-width: max-content;
    cursor: pointer;
    border-radius: 25px;
    border: 0px solid transparent;
    &:hover {
        color: var(--core20);
    }
}
.selected {
    padding: 12px 24px 12px 24px;
    border-radius: 25px;
    border: 2px solid transparent;
    transition: border-color 800ms cubic-bezier(0.77, 0, 0.175, 1);

    &:hover {
        transition: border-color 800ms cubic-bezier(0.77, 0, 0.175, 1);
    }

    &.active {
        transition: border 800ms cubic-bezier(0.77, 0, 0.175, 1), color 800ms cubic-bezier(0.77, 0, 0.175, 1);
    }
}
@include screen('medium') {
    .wrapper {
        padding: 0 0;
    }
    .dark {
        .button {
            border: 1px solid var(--core20) !important;
        }
    }

    .buttons {
        box-shadow: none !important;
        border-radius: 0 !important;

        .button {
            border: 1px solid var(--core80);
            padding: 0;
        }
        .middle {
            border-radius: 0;
            border-left: 0 !important;
            border-right: 0 !important;
        }
        .last {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            border-left: 0 !important;
            margin-right: 20px;
        }
        .first {
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0 !important;
            margin-left: 20px;
        }
    }
}

.slideHidden {
    max-height: 0 !important;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden !important;
}

.slideVisible {
    transition: max-height 0.3s ease-in-out;
}

@import '@/assets/scss/mixins';

.card,
.card_dark {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 24px;
    gap: 32px;
    border-radius: 12px;
    height: 384px;
    max-width: 384px;
    background: var(--core90);
    cursor: pointer;
    overflow-x: hidden;

    .title,
    .title_dark {
        -moz-hyphens: auto;
        -o-hyphens: auto;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
    .title {
        //typography H2
        @include style-font(36px, 44px, -1px);
        @media (max-width: 700px) {
            @include style-font(30px, 36px, -0.8px);
        }
        overflow: auto;
        color: var(--core20);
    }
    .title_dark {
        //typography H2
        @include style-font(36px, 44px, -0.8px);
        @media (max-width: 700px) {
            @include style-font(30px, 36px, -0.6px);
        }
        overflow: auto;
        color: var(--core80);
    }
}
.card_dark {
    background: var(--core10);
}
.tag {
    color: var(--core40);
}
.tag_dark {
    color: var(--core60);
}

.spanTitle {
    max-width: calc(100% - 20px);
}
.btn {
    position: absolute;
    z-index: 10;
    right: 12px;
    bottom: 12px;
}
.hidden {
    display: none;
}

@include screen('medium') {
    .card,
    .card_dark {
        height: 353px;
        max-width: 353px;
    }
}

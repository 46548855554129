@import '../../assets/scss/mixins';
.container {
    padding-top: 48px;
}
.border,
.borderDark {
    border-style: solid;
    border-width: 4px;
    border-color: var(--core20);
    padding: 16px 24px;
}
.borderDark {
    border-color: var(--core80);
}

.light {
    h1,
    h2,
    h3,
    h4,
    p,
    a,
    small,
    blockquote {
        @include theme($light-theme-uni-text...);
    }
    h1 {
        @include style-font(60px, 64px, -2.2px);
    }
    h2 {
        @include style-font(36px, 44px, -1px);
    }
    h3 {
        @include style-font(24px, 34px, -0.6px);
        font-feature-settings: $settings-numerals;
    }
    h4 {
        font-weight: 700;
        @include style-font(20px, 30px, -0.4px);
    }
    p {
        @include style-font(20px, 30px, -0.4px);
    }
    a {
        @include style-font(20px, 24px, -0.4px);
        text-decoration-line: underline;
    }
    small {
        @include style-font(16px, 22px, -0.2px);
    }
    blockquote {
        @include style-font(30px, 40px, -1.6px);
    }
}
.dark {
    h1,
    h2,
    h3,
    h4,
    p,
    a,
    small,
    blockquote {
        @include theme($dark-theme-uni-text...);
    }
    h1 {
        @include style-font(60px, 64px, -2px);
    }
    h2 {
        @include style-font(36px, 44px, -0.8px);
    }
    h3 {
        @include style-font(24px, 34px, -0.4px);
        font-feature-settings: $settings-numerals;
    }
    h4 {
        font-weight: 600;
        @include style-font(20px, 30px, -0.2px);
        font-feature-settings: $settings;
        color: $dark-text-color;
    }
    p {
        @include style-font(20px, 30px, -0.2px);
    }
    a {
        @include style-font(20px, 24px, -0.2px);
        font-feature-settings: $settings-numerals;
        text-decoration-line: underline;
    }
    small {
        font-size: 16px;
        line-height: 22px;
        font-feature-settings: $settings-numerals;
    }
    blockquote {
        @include style-font(30px, 40px, -1.4px);
    }
}
@include screen('medium') {
    .container {
        padding: 48px 20px 0 20px;
    }
    .light {
        h1 {
            @include style-font(48px, 54px, -1.4px);
        }
        h2 {
            @include style-font(30px, 36px, -0.8px);
        }
        h3 {
            @include style-font(24px, 34px, -0.6px);
            font-feature-settings: $settings-numerals;
        }
        h4 {
            font-weight: 700;
            @include style-font(20px, 30px, -0.4px);
        }
        p {
            @include style-font(20px, 28px, -0.6px);
        }
        a {
            @include style-font(20px, 24px, -0.6px);
            text-decoration-line: underline;
            font-feature-settings: $settings-numerals;
        }
        small {
            font-size: 16px;
            line-height: 22px;
            font-feature-settings: $settings-numerals;
        }
        blockquote {
            @include style-font(24px, 32px, -1px);
        }
    }
    .dark {
        h1 {
            @include style-font(48px, 54px, -1.2px);
        }
        h2 {
            @include style-font(30px, 36px, -0.6px);
        }
        h3 {
            @include style-font(24px, 34px, -0.6px);
            font-feature-settings: $settings-numerals;
        }
        h4 {
            font-weight: 600;
            @include style-font(20px, 30px, -0.2px);
        }
        p {
            @include style-font(20px, 30px, -0.4px);
        }
        a {
            @include style-font(20px, 24px, -0.4px);
            text-decoration-line: underline;
            font-feature-settings: $settings-numerals;
        }
        small {
            font-size: 16px;
            line-height: 22px;
            font-feature-settings: $settings-numerals;
        }
        blockquote {
            @include style-font(24px, 32px, -0.8px);
        }
    }
}

@import '@/assets/scss/mixins';
.container {
    padding-top: 48px;
}
.description,
.descriptionDark {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    p,
    a {
        @include style-font(16px, 22px, -0.2px); //typography small
    }
    a {
        font-feature-settings: $settings-numerals;
        text-decoration-line: underline;
    }
}
.source {
    min-height: 44.5px;
    height: 44.5px;
    font-size: 11px;
    font-style: italic;
    font-weight: light;
}
.descriptionDark {
    .source {
        color: var(--core60);
    }
    .imageDescription {
        color: var(--core80);
    }
    p,
    a {
        font-size: 16px;
        line-height: 22px;
        font-feature-settings: $settings-numerals;
    }
    a {
        font-feature-settings: $settings-numerals;
        text-decoration-line: underline;
    }
}
.imageDescription {
    color: var(--core20);
}
.source {
    color: var(--core60);
    padding-top: 24px;
}

@include screen('medium') {
    .description,
    .descriptionDark {
        padding-left: 20px;
        padding-right: 20px;
    }
}
